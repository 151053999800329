import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PlaylistStyled } from "../components/playlists"
import { Playlist } from "../components/playlist"
import { SelectedProviderContext } from "../context/selected-provider-context"
import { Providers } from "../components/providers"
import { ErrorBoundary } from "../components/errors"
import { usePersistedState } from "../hooks/usePersistedState"
import { providers } from "../data/playlist-data"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { imagePath } from "../images"

const LinkStyled = styled(Link)`
  height: 40px;
  border-radius: 40px;
  background: #111;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 800;
  letter-spacing: 0.75px;
  max-width: 80%;
  width: 100%;
  border: 2px solid #fff;
  text-decoration: none;
  max-width: 220px;
  white-space: nowrap;
  margin-top: 30px;

  svg {
    margin-left: -10px;
    margin-right: 10px;
    flex-shrink: 0;
    width: 16px;
  }
`

const PlaylistPage = ({ pageContext }) => {
  const [selectedProvider, setSelectedProvider] = usePersistedState(
    "provider",
    null
  )
  if (!pageContext || !pageContext.data) return <></>
  return (
    <ErrorBoundary>
      <Layout>
        <SEO
          title={`${pageContext.data.title}`}
          image={imagePath(pageContext.data.image)}
        />

        <SelectedProviderContext.Provider value={selectedProvider}>
          <Providers
            providers={providers}
            handleProviderClick={setSelectedProvider}
          />

          {selectedProvider && (
            <PlaylistStyled>
              {pageContext && pageContext.data && (
                <Playlist playlistData={pageContext.data} hideMeta showButton />
              )}
            </PlaylistStyled>
          )}

          <LinkStyled to="/">
            <svg
              fill="currentColor"
              style={{ marginRight: `10px` }}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>{" "}
            See all playlists
          </LinkStyled>
        </SelectedProviderContext.Provider>
      </Layout>
    </ErrorBoundary>
  )
}

export default PlaylistPage
